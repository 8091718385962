import React, { FunctionComponent, ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { useStaticQuery, graphql } from 'gatsby';
import { SEO, GeneralJumbotron, SummaryTemplate, CallToAction } from '../components';
import { MainLayout } from '../layouts';
import { DentalImageQuery } from '../shared/interfaces';
import * as sharedStyles from '../styles/shared.module.scss';

const DentalPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<DentalImageQuery>(graphql`
        query {
            dentalBackgroundImage: file(name: {eq: "dentalBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
            dentalSummaryImage: file(name: {eq: "dentalSummary"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Dental"/>
            <GeneralJumbotron
                title="Dental"
                backgroundImageURL={queryResult?.dentalBackgroundImage.publicURL}
                backgroundText="Dental"
            />
            <SummaryTemplate image={queryResult?.dentalSummaryImage.publicURL}>
                <p>
                    {`
                        We realize dental claims don’t occur with the same frequency as other bodily injury claims, and is not as well understood. It’s hard to find a Provider and can be expensive with overtreatment and unnecessary services. That’s why we’ve contracted with a national provider who is equipped to handle these cases with world class concierge service. Each coordinator has a clinical background in dentistry, so you can rest assure that your client is good hands and treatment is appropriate.
                    `}
                </p>
                <div className={sharedStyles.listWrapper}>
                    <p>{`Specialty Services include:`}</p>
                    <ul>
                        <li>{`Regular Dentistry`}</li>
                        <li>{`Oral Surgeons`}</li>
                        <li>{`TML Specialist`}</li>
                        <li>{`Endodontists`}</li>
                        <li>{`Periodontists`}</li>
                    </ul>
                </div>
                <p>
                    {`To learn more, call us at `}
                    <a href="tel:+18337244111">
                        <FontAwesomeIcon icon={faPhoneAlt}/>
                        {`1-833-PCH-4111`}
                    </a>
                </p>
            </SummaryTemplate>
            {/* <CallToAction/> */}
        </MainLayout>
    );
}

export default DentalPage;
